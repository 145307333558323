<template>
  <div class="page-home overflow-h">
    <div class="header-wrapper bg-fff">
      <header class="header flex flex-b center">
        <div class="flex">
          <div class="logo">
            <router-link :to="'/?lang=' + lang"
              ><img src="../assets/img/logo.png"
            /></router-link>
          </div>
          <div>
            <nav class="nav">
              <ul class="flex">
                <li>
                  <router-link :to="'/?lang=' + lang"
                    >{{ $t("home.webname") }}
                  </router-link>
                </li>
                <li>
                  <router-link :to="'/?lang=' + lang"
                    >{{ $t("home.home") }}
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div class="flex">
          <div :class="'flex mr-100 f-18 ' + langys">
            <span class="ico-lang mr-4"></span>
            <div class="lang">
              <span @click="changeLang('zh')" :class="{ active: lang == 'zh' }"
                >CH</span
              >
              <span>/</span>
              <span @click="changeLang('en')" :class="{ active: lang == 'en' }"
                >EN</span
              >
            </div>
          </div>

          <div class="btn-login" v-if="!userInfo.id">
            <router-link class="block" :to="'/login?lang=' + lang"
              >{{ $t("home.login") }}
            </router-link>
          </div>

          <div class="user flex flex-c f-14 pos-r" v-else>
            {{ userInfo.userName }}
            <span class="ico-user ml-10">
              <img src="../assets/img/user.png" />
            </span>
            <div class="tc" @click="quit">{{ $t("home.tc") }}</div>
          </div>
        </div>
      </header>
    </div>

    <router-view></router-view>

    <footer class="center text-c f-12 c-666">
      <p class="pt-10 pb-10">
        <a href="https://beian.miit.gov.cn/" target="_blank">{{
          $t("home.beian")
        }}</a>
      </p>
    </footer>
  </div>
</template>

<script>
import langMinix from "../common/lang";
import { Event } from "../utils/Event.js";

export default {
  name: "Home",
  components: {},
  data: () => {
    return {
      lang: "",
      langys: "mt-80",
      userInfo: {},
    };
  },
  created() {
    let clang = this.$route.query.lang;
    let envLang = window.navigator.language.slice(0, 2);
    this.lang = clang || envLang;
    console.log(this.lang)

    if (!location.href.includes("lang")) {
      this.$router.replace(`/?lang=${this.lang}`);
    }
  },
  activated() {
    this.user();
  },

  mounted() {
    this.user();
  },
  minixs: [langMinix],
  methods: {
    user() {
      if (localStorage.getItem("user")) {
        this.userInfo = JSON.parse(localStorage.getItem("user"));
        this.langys = "mt-80";
      }
    },
    quit() {
      localStorage.clear();
      this.userInfo = {};
      this.langys = "mt-80";
    },
    changeLang(val) {
      this.$i18n.locale = val;
      this.lang = val;
      let url;
      if (location.href.includes("?")) {
        if (location.href.includes("lang")) {
          url = location.href.replace(/lang=\w+/, `lang=${val}`);
        } else {
          url = location.href + `&lang=${val}`;
        }
      } else {
        url = location.href + `?lang=${val}`;
      }
      location.replace(url);
      Event.emit("lang");
      console.log(url);
    },
  },
};
</script>
<style lang="less" scoped>
.page-home {
  width: 1600px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1200px) {
  .page-home {
    width: 1200px;
  }
}

.header-wrapper {
  background: white;
}

.header {
  height: 146px;
  width: var(--w1600);
}

.logo {
  width: 131px;
  height: 50px;
  overflow: hidden;
  margin-top: 61px;
  margin-right: 52px;

  img {
    width: 131px;
    height: 50px;
  }
}

.nav {
  font-size: 24px;
  font-weight: bold;
  margin-top: 80px;
}

.nav ul li {
  padding-right: 25px;
  padding-left: 25px;
  position: relative;

  a {
    color: #000;
  }
}

.nav ul li::after {
  position: absolute;
  right: 0;
  top: 12px;
  width: 2px;
  height: 15px;
  background: #434343;
  content: "";
  display: inline-block;
}

.nav ul li:last-child::after {
  display: none;
}

.btn-login {
  width: 120px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  background: #ff000f;
  font-size: 14px;
  font-family: ABB;
  font-weight: 400;
  margin-top: 77px;

  a {
    color: #ffffff;
  }
}

.ico-lang {
  width: 27px;
  height: 27px;
  background: url("../assets/img/ico-lang.png");
}

.mt-80 {
  margin-top: 80px;
}

.mr-100 {
  margin-right: 60px;
}

.lang {
  color: #717171;
  cursor: pointer;

  .active {
    color: #ff000f;
    font-weight: bolder;
  }
}

.user {
  margin-top: 46px;
  cursor: pointer;

  .ico-user {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;

    img {
      width: 50px;
      height: 50px;
    }
  }

  .tc {
    width: 129px;
    height: 47px;
    line-height: 55px;
    text-align: center;
    background: url("../assets/img/ico-tc.png") no-repeat;
    position: absolute;
    top: 74px;
    font-size: 18px;
    z-index: 1;
    left: -30px;
    padding-left: 23px;
    color: #717171;
    display: none;
  }
}

.user:hover {
  .tc {
    display: block;
  }
}
</style>
